<script setup lang="ts">
const props = withDefaults(
	defineProps<{
		image?: string;
		imageMobile?: string;
		background?: string;
		backgroundMob?: string;
		welcomeBanner?: boolean;
		hideBg?: boolean;
		fetchPriority?: boolean;
	}>(),
	{
		image: "",
		imageMobile: "",
		background: "",
		backgroundMob: "",
		welcomeBanner: false,
		hideBg: true
	}
);

const prepareImgUrl = useImage();

const backgroundImage = `url(${prepareImgUrl(props.background, { format: "avif" })})`;
</script>

<template>
	<div class="banner-container">
		<ABanner
			:img-attrs="{
				alt: 'banner',
				src: image,
				format: 'avif',
				width: 954,
				height: 516,
				loading: fetchPriority ? 'eager' : 'lazy',
				fetchpriority: fetchPriority ? 'high' : 'auto',
				decoding: fetchPriority ? 'sync' : 'async'
			}"
			:sources="[
				{
					srcset: prepareImgUrl(imageMobile, {
						format: 'avif',
						width: 540,
						height: 360,
						loading: 'lazy'
					}),
					width: 540,
					height: 360,
					format: 'avif',
					media: '(max-width: 767px)',
					loading: 'lazy'
				}
			]"
			:background="backgroundImage"
			:class="['banner', { 'hide-bg': hideBg }, { welcome: welcomeBanner }]"
		>
			<NuxtImg
				:src="backgroundMob"
				class="image-mob"
				width="336"
				height="348"
				format="avif"
				alt="background-mob"
				loading="lazy"
			/>
			<div class="banner-content">
				<slot name="badge" />
				<slot />
			</div>
		</ABanner>
		<div class="banner-button">
			<slot name="button" />
		</div>
	</div>
</template>

<style scoped lang="scss">
.banner {
	@include media-breakpoint-down(md) {
		--a-banner-default-image-height: 242px;
		--a-banner-default-pictute-height: 242px;
		--a-banner-default-height: 384px;
		--a-banner-default-picture-top-position: 0;
		--a-banner-default-picture-bottom-position: auto;
		margin: 0 auto;
		display: flex;
		justify-content: center;
		overflow: visible;
	}

	&.welcome {
		@include media-breakpoint-down(md) {
			--a-banner-default-border-radius: 0 0 16px 16px;
			overflow: visible;

			.image-mob {
				height: 347px;
				transform: translate(-50%, 0);
			}
		}
	}
}
.banner-container {
	@include media-breakpoint-up(lg) {
		min-width: 1136px;
		max-width: 1136px;
	}
	&:deep(.banner) {
		&::after {
			content: "";
			display: block;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background: var(--crosby);
			border-radius: 16px;
			z-index: -1;
		}
	}
	@include media-breakpoint-down(md) {
		height: 410px;

		&:deep(.banner) {
			&::after {
				display: none;
			}
		}
	}
	.banner-button {
		display: none;

		@include media-breakpoint-down(md) {
			display: flex;
			position: absolute;
			bottom: 0;
			left: 50%;
			transform: translate(-50%, 0);
		}
	}
}
.banner {
	flex-shrink: 0;
	background-repeat: no-repeat;
	background-size: cover;

	@include media-breakpoint-down(md) {
		&.hide-bg {
			background: none;
		}
	}

	.image-mob {
		display: none;

		@include media-breakpoint-down(md) {
			border-radius: 10px;
			display: block;
			position: absolute;
			bottom: 0;
			left: 50%;
			transform: translate(-50%, 0);
			width: 100%;
			z-index: -1;
		}
	}

	&-content {
		padding: gutter(6) gutter(8);
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		color: var(--cannes);
		gap: gutter(1);

		.welcome & {
			gap: gutter(4);

			@include media-breakpoint-down(md) {
				gap: gutter(8);
			}
		}

		@include media-breakpoint-down(md) {
			padding: 0 gutter(2) gutter(6);
			justify-content: flex-end;
			align-items: center;
			width: 100%;

			.welcome & {
				justify-content: flex-start;
				padding-top: gutter(8);
			}
		}
	}
}
</style>

<style lang="scss">
.banner-container {
	button {
		flex-shrink: 0;
		width: 256px;
	}
}
.banner-content {
	button {
		@include media-breakpoint-down(md) {
			display: none;
		}
	}
	.welcome-offer {
		display: flex;
		flex-direction: column;
		gap: gutter(0.5);

		@include media-breakpoint-down(md) {
			align-items: center;
			gap: 0;
		}

		.title {
			font-size: 54px;
			font-weight: 700;
			line-height: 64px;
			text-transform: uppercase;

			@include media-breakpoint-down(md) {
				font-size: 40px;
				line-height: 48px;
			}
		}

		.subtitle {
			display: flex;
			flex-wrap: wrap;
			gap: gutter(0.5);
			font-size: 38px;
			font-style: normal;
			font-weight: 700;
			line-height: 50px;
			text-transform: uppercase;

			@include media-breakpoint-down(md) {
				flex-direction: column;
				align-items: center;
				gap: 0;
				font-size: 24px;
				line-height: 28px;

				.description {
					text-transform: none;
				}
			}
		}
	}
}
</style>
